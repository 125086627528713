import { $, addAction, addFilter } from '@situation/setdesign.util';
import lax from 'lax.js';

/**
 * Register page background top position style.
 *
 * @param {Object} styles
 * @returns {Object}
 */
const registerPageBgTopStyle = (styles) => {
    const $firstSection = $('.content-section:first-of-type.content-section--has-bg');
    if ($firstSection.length) {
        return { ...styles, 'page-bg-top': () => `${$firstSection.outerHeight()}px` };
    }
    return styles;
};

/**
 * Register animation for background elements.
 */
const registerBackgroundAnimations = () => {
    const $bgGlow = $('.bg-glow');
    const $bgShape = $('.bg-s-shape');
    if ($bgGlow.length) {
        lax.addElement($bgGlow[0], {
            scrollY: {
                translateY: [
                    ['elInY', 'elOutY'],
                    [-100, 100],
                ],
            },
        });

        if ($bgShape.length) {
            lax.addElement($bgShape[0], {
                scrollY: {
                    translateY: [
                        ['elInY', 'elOutY'],
                        [-200, 200],
                    ],
                },
            });
        }
    }
};

addFilter('css-vars/register', registerPageBgTopStyle);
addAction('registerAnimationsDesktop', registerBackgroundAnimations);
