import { $, addAction } from '@situation/setdesign.util';

addAction('registerAnimationsNoTouch', () => {
    const $indicatorEl = $('#pointer-indicator');
    const $tooltip = $indicatorEl.find('.pointer-indicator__tooltip');
    let hasShown = false;
    $('a, button')
        .on('mouseenter', (e) => {
            const $el = $(e.currentTarget);
            if ($el.data('tooltip')) {
                $indicatorEl.addClass('pointer-indicator--tooltip');
                $tooltip.html($el.data('tooltip'));
            }

            $indicatorEl.addClass('pointer-indicator--hover');
        })
        .on('mouseleave', () => {
            $indicatorEl.removeClass('pointer-indicator--hover pointer-indicator--tooltip');
            $tooltip.empty();
        });

    addAction('updateMousePos', (pos) => {
        $indicatorEl[0].style.transform = `translate(${pos.x}px, ${pos.y}px)`;

        if (!hasShown) {
            hasShown = true;
            $indicatorEl.addClass('pointer-indicator--show');
        }
    });
});
