import {
    $, addAction, doAction, INIT, LAYOUT,
} from '@situation/setdesign.util';

const paginationCount = 12;

const setupSpotlightCardListPagination = () => {
    $('.spotlight-card-list--multi').each((i, container) => {
        const $container = $(container);
        const $columns = $container.find('.spotlight-card-list__column');
        const $more = $container.find('.spotlight-card-list__more-btn');
        let currentIndex = 0;

        const showNextColumns = () => {
            let delay = 0;
            const $set = $columns
                .slice(currentIndex, currentIndex + paginationCount)
                .addClass('spotlight-card-list__column--load');
            doAction(LAYOUT);
            setTimeout(() => {
                $set.each((j, col) => {
                    $(col).css('transitionDelay', `${delay}s`);
                    delay += 0.15;
                }).addClass('spotlight-card-list__column--show');
            }, 100);

            currentIndex += paginationCount;

            if (currentIndex >= $columns.length) {
                $more.remove();
            }
        };

        showNextColumns();
        $more.on('click', showNextColumns);
    });
};

addAction(INIT, setupSpotlightCardListPagination);
