import {
    $, addAction, INIT, LAYOUT,
} from '@situation/setdesign.util';

const containerComponents = ['spotlight-card', 'group-container'];
const ranges = {
    xs: [320, 575],
    sm: [576, 767],
    md: [768, 991],
    lg: [992, 1199],
    xl: [1200, null],
};
// Uncomment to enable -max modifier
const allModifiers = (() => Object.keys(ranges)
    .map((b) => [`--${b}`])
    .flat())();

const getModifiers = (width) => {
    const modifiers = [];
    Object.keys(ranges).forEach((key) => {
        if (width >= ranges[key][0]) {
            modifiers.push(`--${key}`);
        }
        /* if (ranges[key][1] && width <= ranges[key][1]) {
            modifiers.push(`--${key}-max`);
        } */
    });
    return modifiers;
};

const updateElement = ($el, base, modifiers) => {
    const { classList } = $el[0];
    const exclude = allModifiers.filter((x) => !modifiers.includes(x));
    classList.remove(...exclude.map((x) => base + x));
    classList.add(...modifiers.map((x) => base + x));
};

addAction(INIT, () => {
    const groups = containerComponents.map((classItem) => ({
        base: classItem,
        el: $(`.${classItem}`),
    }));

    const assignModifiers = () => {
        groups.forEach((item) => {
            item.el.each((i, el) => {
                const $el = $(el);
                updateElement($el, item.base, getModifiers($el.width()));
            });
        });
    };

    addAction(LAYOUT, assignModifiers);
});
