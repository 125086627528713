import { $, addAction, INIT } from '@situation/setdesign.util';
import lax from 'lax.js';

const filterElements = [
    '.content-column__inner--sticky-centered',
    '.content-column__inner--sticky',
    '.scroll--left',
    '.scroll--right',
    '.scroll--bottom',
    '.animate-in-up',
    '.content-column--bg-primary-light',
    '.content-column--bg-secondary-light',
].join(', ');
const targetElements = [
    '.column-text > *',
    '.content-column__inner > .button',
    '.content-column--bg-primary-light',
    '.content-column--bg-secondary-light',
    '.jobs-list__item',
].join(', ');

const filterTargets = (i, el) => {
    const $el = $(el);
    return !($el.parents(filterElements).length > 0 || SetDesign.viewport.isInViewport($el));
};

const setTargets = () => $(targetElements).filter(filterTargets).addClass('animate-in-up');

const registerAnimations = () => {
    lax.addElements('.animate-in-up', {
        scrollY: {
            opacity: [
                ['elInY+50', 'elInY+screenHeight/3'],
                [0, 1],
            ],
            translateY: [
                ['elInY+50', 'elInY+screenHeight/2.5'],
                [20, 0],
            ],
        },
    });
};

addAction(INIT, setTargets);
addAction('registerAnimations', registerAnimations);
