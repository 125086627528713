import {
    $, addAction, doAction, INIT, SCROLL,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const STICKY_SELECTOR = 'sticky-header';
    const STICKY_MODIFIER = `${STICKY_SELECTOR}--sticky`;
    const STICKY_MODIFIER_ABS = `${STICKY_SELECTOR}--absolute`;
    const STICKY_MODIFIER_TOP = `${STICKY_SELECTOR}--top`;
    const STICKY_HEADER_SHOW = 'stickyHeaderShow';
    const STICKY_HEADER_HIDE = 'stickyHeaderHide';
    const $header = $(`.${STICKY_SELECTOR}`).addClass(STICKY_MODIFIER_TOP);
    const $body = $('body');
    if (!$header.length) {
        return;
    }

    let isVisible = false;
    let oldPosition = 0;

    const publishHeaderToggle = (shouldShow) => {
        if (isVisible && !shouldShow) {
            doAction(STICKY_HEADER_HIDE);
        } else if (!isVisible && shouldShow) {
            doAction(STICKY_HEADER_SHOW);
        }
    };

    addAction(SCROLL, (e, position) => {
        publishHeaderToggle(position.top > 0 && position.top < oldPosition);
        oldPosition = position.top;
    });

    addAction(STICKY_HEADER_SHOW, () => {
        $header.addClass(STICKY_MODIFIER).removeClass(STICKY_MODIFIER_TOP);
        isVisible = true;

        if (!$header.hasClass(STICKY_MODIFIER_ABS)) {
            $body.addClass('header-offset');
        }
    });

    addAction(STICKY_HEADER_HIDE, () => {
        $header.removeClass(STICKY_MODIFIER).addClass(STICKY_MODIFIER_TOP);
        isVisible = false;

        if (!$header.hasClass(STICKY_MODIFIER_ABS)) {
            $body.removeClass('header-offset');
        }
    });
});
