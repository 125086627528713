import {
    _,
    $,
    addAction,
    doAction,
    removeAction,
    INIT,
    LAYOUTEND,
} from '@situation/setdesign.util';
import lax from 'lax.js';

let mousePos = { x: 0, y: 0 };

const updateMousePos = (e) => {
    mousePos = { x: e.clientX, y: e.clientY };
    window.requestAnimationFrame(() => {
        doAction('updateMousePos', mousePos);
    });
};

addAction(LAYOUTEND, () => window.isTouchDevice());

const initLax = () => {
    lax.init();
    lax.addDriver('scrollY', () => window.scrollY);

    if (!window.isTouchDevice()) {
        lax.addDriver('mouseX', () => mousePos.x);
        lax.addDriver('mouseY', () => mousePos.y);
        document.onmousemove = _.throttle(updateMousePos, 50, { leading: true, trailing: true });
        doAction('registerAnimationsNoTouch');
    }
    if (SetDesign.viewport.width() >= 575) {
        doAction('registerAnimationsDesktop');
    }

    doAction('registerAnimations');
    $('body').addClass('animate-start');
    setTimeout(() => {
        removeAction(LAYOUTEND, initLax);
    }, 0);
};

addAction(LAYOUTEND, initLax);
addAction(
    INIT,
    () => {
        window.requestAnimationFrame(() => {
            $('body').addClass('js-init');
        });
    },
    200,
);
