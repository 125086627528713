import {
    $, addAction, INIT, LAYOUT,
} from '@situation/setdesign.util';
import { splitInnerHTML } from '../util/splitText';
import TriggeredAnimation from '../util/TriggeredAnimation';

const removeLastSpace = (str) => {
    const lastIndex = str.lastIndexOf('&nbsp;');
    return lastIndex !== -1 ? str.slice(0, lastIndex) + str.slice(lastIndex + 6) : str;
};

const initializeHeadings = (index, el) => {
    const $el = $(el);
    $el.find('p, div').each((i, part) => {
        const $part = $(part);
        $part.replaceWith($part.contents());
    });

    $el.data('originalHTML', $el.html());
};

const rewriteElement = (i, heading) => {
    const $heading = $(heading);
    $heading.removeClass('heading--transition');
    $heading.html($heading.data('originalHTML'));
    const $words = $(
        splitInnerHTML(heading, ' ', false, true)
            .map((word) => (!word || word.includes('<') ? word : `<span class="word">${word}&nbsp;</span>`))
            .join(''),
    );
    $words
        .not((_, w) => $(w).hasClass('word') || $(w).is('br'))
        .addClass('word')
        .append('&nbsp;');

    $heading.html(
        removeLastSpace(
            $words
                .map((k, word) => $(word).prop('outerHTML'))
                .get()
                .join(''),
        ),
    );

    SetDesign.groupByRow($heading.find('> *:not(br)')).map((line) => line.wrapAll('<span class="line" />'));

    $heading.find('br').remove();
    $heading.find('.word').each((j, word) => {
        // eslint-disable-next-line no-param-reassign
        word.style.transitionDelay = `${j * 0.12}s`;
    });

    $heading.addClass('heading--transition');
};

addAction(INIT, () => {
    const $headings = $('.heading--animate');
    const $animateWords = $headings.filter('.heading--animate-words');
    $animateWords.each(initializeHeadings);
    addAction(LAYOUT, (hasChanged) => {
        if (hasChanged) {
            $animateWords.each(rewriteElement);
        }
    });

    TriggeredAnimation.create($headings, 'heading--show');
});
