import { $, addAction, INIT } from '@situation/setdesign.util';
import TriggeredAnimation from '../util/TriggeredAnimation';

const initCallback = (i, el) => {
    let delay = 1;
    $(el)
        .find('li')
        .each((j, li) => {
            $(li)
                .css('transition-delay', `${delay}s`)
                .find('strong')
                .css('transition-delay', `${delay + 0.3}s`);

            delay += 0.2;
        });
};

addAction(INIT, () => {
    const animation = new TriggeredAnimation($('.feature-list'), 'feature-list--show', {
        delay: 0,
        easing: 0,
    });
    animation.setCallback('init', initCallback);
});
