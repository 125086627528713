import { addAction } from '@situation/setdesign.util';
import lax from 'lax.js';

addAction('registerAnimationsDesktop', () => {
    lax.addElements('.scroll--left', {
        scrollY: {
            opacity: [
                ['elInY+(elHeight/2)', 'elCenterY'],
                [0, 1],
            ],
            translateX: [['elInY+(elHeight/2)', 'elOutY'], [200, 0], { easing: 'easeOutQuart' }],
        },
    });

    lax.addElements('.scroll--right', {
        scrollY: {
            opacity: [
                ['elInY+(elHeight/2)', 'elCenterY'],
                [0, 1],
            ],
            translateX: [['elInY+(elHeight/2)', 'elOutY'], [-200, 0], { easing: 'easeOutQuart' }],
        },
    });
});

addAction('registerAnimations', () => {
    lax.addElements('.scroll--bottom', {
        scrollY: {
            opacity: [
                ['elInY', 'elCenterY'],
                [0, 1],
            ],
            translateY: [['elInY', 'elOutY'], [100, 0], { easing: 'easeOutQuart' }],
        },
    });
});
