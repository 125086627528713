import { $, addAction, INIT } from '@situation/setdesign.util';

const shuffleArray = (inputArray) => {
    const array = [...inputArray];

    for (let i = array.length - 1; i > 0; i -= 1) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

const shuffleGroupContainers = (index, contentColumn) => {
    const $contentColumn = $(contentColumn);
    const $groupContainers = $contentColumn.children('.group-container');
    const groupContainersArray = Array.from($groupContainers);
    const shuffledArray = shuffleArray(groupContainersArray);
    $contentColumn.empty().append(shuffledArray);
};

addAction(
    INIT,
    () => $('.gallery-wall__items .content-column__inner').each(shuffleGroupContainers),
    5,
);
