import { $, addAction, INIT } from '@situation/setdesign.util';

const toggleActiveClass = ($element, isActive) => {
    const labelFor = $element.attr('id');
    $(`label[for="${labelFor}"]`)
        .closest('div')
        .addClass('floating-label')
        .toggleClass('floating-label--active', isActive)
        .toggleClass('floating-label--inactive', !isActive);
};

const activateField = (element) => {
    toggleActiveClass($(element), true);
};

const deactivateField = (element) => {
    const $element = $(element);
    if (!$element.val()) {
        toggleActiveClass($element, false);
    }
};

const toggleActiveField = (element) => {
    const $element = $(element);
    toggleActiveClass($element, $element.val() !== '');
};

const setupElements = () => {
    const inputTypes = ['email', 'number', 'password', 'search', 'tel', 'text', 'time', 'url'];
    const inputElements = `${inputTypes
        .map((type) => `input[type="${type}"]`)
        .join(', ')}, textarea`;
    $(inputElements)
        .on('focus', (e) => activateField(e.target))
        .on('blur', (e) => deactivateField(e.target))
        .on('change', (e) => toggleActiveField(e.target))
        .each((i, element) => toggleActiveField(element));
};

addAction(INIT, () => {
    setupElements();
    $(document).on('gform_post_render', setupElements);
});
