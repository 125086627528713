import {
    $, addAction, INIT, LAYOUT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const $matchHeight = $('.group-container--match-height');

    const matchHeight = () => {
        $matchHeight.css('min-height', '');
        const rows = SetDesign.groupByRow($matchHeight);
        rows.forEach(($row) => {
            if ($row.length > 1) {
                const maxHeight = Math.max(...$row.map((i, el) => $(el).outerHeight()).get());
                $row.css('min-height', `${maxHeight}px`);
            }
        });
    };
    if ($matchHeight.length) {
        addAction(LAYOUT, matchHeight);
    }
});
