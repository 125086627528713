import { debounce } from 'lodash';

const easeOutExpo = (x) => (x === 1 ? 1 : 1 - 2 ** (-10 * x));
class IncrementalTimer {
    constructor(delay, easing) {
        this.incrementor = delay;
        this.easeRate = easing >= 0 ? easing : 10;
        this.currentIteration = 0;
        this.delay = 0;
        this.reset = debounce(this.reset.bind(this), delay);
    }

    reset() {
        this.delay = 0;
        this.currentIteration = 0;
    }

    execute(callback) {
        setTimeout(() => {
            window.requestAnimationFrame(() => {
                callback();
                this.reset();
            });
        }, this.delay);

        this.currentIteration += 1;
        this.delay = this.easeRate
            ? this.incrementor * easeOutExpo(this.currentIteration / this.easeRate)
            : this.incrementor * this.currentIteration;

        this.reset();
    }
}

export default IncrementalTimer;
