import { addAction } from '@situation/setdesign.util';
import lax from 'lax.js';

addAction('registerAnimations', () => {
    lax.addElements('.gallery-wall__items .content-column__inner', {
        scrollY: {
            translateX: [
                ['elInY', 'elOutY'],
                ['elHeight*.1', '-(elWidth-(Math.min(screenWidth, 1600))+(elHeight*.1))'],
            ],
        },
    });
});
