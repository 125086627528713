import {
    $, addAction, LAYOUT, INIT,
} from '@situation/setdesign.util';

const adjustCentering = (i, column) => {
    const $col = $(column);
    $col.css('min-height', '').removeClass('content-column--sticky-centered--show');

    if ($col.css('position') === 'sticky') {
        const $inner = $col.find('.content-column__inner');
        const colHeight = $inner.outerHeight();
        const outerHeight = Math.min(
            SetDesign.viewport.height() / 2,
            $col.parents('.content-row').outerHeight() / 2,
        );
        const diff = outerHeight + colHeight / 2;
        $col.css('min-height', `calc(${diff}px - var(--dynamic__header-height, 0px))`).addClass(
            'content-column--sticky-centered--show',
        );
    }
};

addAction(LAYOUT, () => $('.content-column--sticky-centered').each(adjustCentering));
addAction(INIT, () => {
    $('.content-column--sticky-centered, .content-column--sticky')
        .parents('.content-section')
        .addClass('content-section--overflow');
});
