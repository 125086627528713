import {
    _, addAction, READY, LAYOUT, LAYOUTEND, SCROLL,
} from '@situation/setdesign.util';
import IncrementalTimer from './IncrementalTimer';

class TriggeredAnimation {
    constructor($elements, showClassName, { delay = 700, easing = 10 } = {}) {
        this.$elements = $elements;
        this.showClassName = showClassName;
        this.timer = new IncrementalTimer(delay, easing);
        addAction(READY, this.bindEvents);
    }

    bindEvents = () => {
        this.initializeElements();
        addAction(LAYOUT, (hasChanged) => {
            if (hasChanged) {
                this.onLayoutChange();
            }
        });

        addAction(LAYOUTEND, () => this.$elements.each(this.onScroll));
        addAction(
            SCROLL,
            _.throttle(() => this.$elements.each(this.onScroll), 500, { leading: false }),
        );
    };

    setCallback(type, callback) {
        if (['init', 'layout', 'inView', 'outOfView'].includes(type)) {
            this[`${type}Callback`] = callback;
        }
        return this;
    }

    initializeElements = () => {
        if (this.initCallback) {
            this.$elements.each((i, el) => this.initCallback(i, el));
        }
    };

    onLayoutChange = () => {
        if (this.layoutCallback) {
            this.$elements.each((i, el) => this.layoutCallback(i, el));
        }
    };

    inView = (i, el) => {
        el.classList.add(this.showClassName);

        if (this.inViewCallback) {
            this.inViewCallback(i, el);
        }
    };

    outOfView = (i, el) => {
        el.classList.remove(this.showClassName);

        if (this.outOfViewCallback) {
            this.outOfViewCallback(i, el);
        }
    };

    onScroll = (i, el) => {
        const inView = SetDesign.viewport.isInViewport(el);
        const rect = el.getBoundingClientRect();
        if (inView && !el.classList.contains(this.showClassName)) {
            this.timer.execute(() => this.inView(i, el));
        } else if (!inView && el.classList.contains(this.showClassName)) {
            if (rect.top > SetDesign.viewport.height()) {
                this.outOfView(i, el);
            }
        }
    };

    static create(...args) {
        return new this(...args);
    }
}

export default TriggeredAnimation;
